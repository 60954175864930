.text-animation-time-adhiba {
  animation: fade linear both;
  animation-timeline: view(block);
  animation-range: contain 20% cover 50%;
}

@keyframes fade {
  0% {
    opacity: 0;

    color: #000000;
  }

  100% {
    opacity: 1;
    color: #FFFFFF;
  }
}





.timeline-head-adhiba-top {
  position: relative;
  z-index: 2;
  -webkit-box-shadow: 1px 41px 36px 0px rgba(0, 16, 26, 1);
  -moz-box-shadow: 1px 41px 36px 0px rgba(0, 16, 26, 1);
  box-shadow: 1px 41px 36px 0px rgba(0, 16, 26, 1);
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 25vh;

  background-color: #00101a;
}

.journy-head-h-timeline {
  font-size: 4rem;

}

.timeline-head-adhiba-bottom {
  position: relative;
  z-index: 1;
  -webkit-box-shadow: 1px -69px 36px 0px rgba(0, 16, 26, 1);
  -moz-box-shadow: 1px -69px 36px 0px rgba(0, 16, 26, 1);
  box-shadow: 1px -69px 36px 0px rgba(0, 16, 26, 1);
  height: 5vh;
  background-color: #00101a;
}




.timeline-circle {
  width: 22px;
  height: 22px;
  background-color: #fff;
  border: 3px solid rgb(0, 0, 0);
  border-radius: 100%;
  position: -webkit-sticky;
  position: sticky;
  top: 30vh;
}

.timeline-centerrilt {
  margin-left: 45px;
}

.timeline-center {
  margin-left: -63px;
  margin-right: 62px;

}


.timeline-container {


  background-color: #00101a;
  color: aquamarine;
  justify-content: center;
  padding-bottom: 40px;


  .vertical-scrollable-timeline {
    width: fit-content;
    list-style-type: none;
    position: relative;

    .list-progress {
      opacity: 0.5;
      width: 2px;
      height: 100%;
      background-color: #22c0de;
      position: absolute;
      left: 46%;
      top: 0vh;
      overflow: hidden;

      .inner {
        position: absolute;
        right: 0;
        bottom: 0;
        height: 100%;
        background-color: #00101a;
        width: 100%;
        transition: .4s all;
      }
    }

    .li-timeline-adhiba {
      position: relative;
      display: flex;
      height: fit-content;
      padding: 100px 0px 0px 0px;

      .image-time-sdhiba {
        height: 250px;
        width: fit-content;
      }

      h1 {
        color: #22c0de;
      }

      .TEXT-RIGHT-TIME-ADHIBA {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }

      .image-left-TIME-ADHIBA {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }






    }
  }

}


































/* new tab */
*,
*:after,
*:before {
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}
.tab-adhiba{
.clearfix:before,
.clearfix:after {
	content: " ";
	display: table;
  outline: 0;
  border: 0;
}
padding: 1.5% 0;
.clearfix:after {
	clear: both;
}





/* /Fun begins/ */
.tab_container {
  padding-top: .75%;
	margin: 0 auto;
	position: relative;
}

input, section {
  clear: both;
  padding-top: 10px;
  display: none;
}

label {
  font-weight: 700;
  font-size: 18px;
  display: block;
  float: left;
  width: 16.5%;
  padding: 1.5em;
  color: #757575;
  cursor: pointer;
  text-decoration: none;
  text-align: center;
  background: #f0f0f0;
}

#tab1:checked ~ #content1,
#tab6:checked ~ #content6,
#tab2:checked ~ #content2,
#tab3:checked ~ #content3,
#tab4:checked ~ #content4,
#tab5:checked ~ #content5 {
  display: block;
  padding: 20px;
  background: #fff;
  color: #999;
  border-bottom: 2px solid #f0f0f0;
}

/* .tab_container .tab-content p,
.tab_container .tab-content h3 {
  -webkit-animation: fadeInScale 0.7s ease-in-out;
  -moz-animation: fadeInScale 0.7s ease-in-out;
  animation: fadeInScale 0.7s ease-in-out;
}
.tab_container .tab-content h3  {
  text-align: center;
} */

.tab-content span{
  color: #313985;
  font-weight: bold;
}
.tab-content .card{
  outline: 0;
  border: 0;
  background-color: #f5f5f5;
}
.tab-content .card-body{
  padding: 0 5%;  
  border-radius: 7px;
}
.tab-content .card-title{
  font-size: 30px;
  color: #313985;
  font-weight: bold;
  text-transform: uppercase;
}
.tab-content .card-text{
  margin-top: 2%;
  color: #757575;
  width: 85%;
  text-align: justify;
}

.tab_container [id^="tab"]:checked + label {
  background: #fff;
  box-shadow: inset 0 3px #313985;
}

.tab_container [id^="tab"]:checked + label .fa {
  color: #313985;
}

label .fa {
  font-size: 1.3em;
  margin: 0 0.4em 0 0;
}

/* /Media query/ */
@media only screen and (max-width: 900px) {
  label span {
    display: none;
    font-size: 12px;
    color: #000000;
  }
  
  /* .tab_container {
    width: 98%;
  } */
}

/* /Content Animation/ */
@keyframes fadeInScale {
  0% {
  	transform: scale(0.9);
  	opacity: 0;
  }
  
  100% {
  	transform: scale(1);
  	opacity: 1;
  }
}

.no_wrap {
  text-align:center;
  color: #313985;
}
.link {
  text-align:center;
}
}














@keyframes one {
  0% {
    transform: translateX(0);
  }

  16% {
    transform: translateX(0);
  }

  20% {
    transform: translateX(100%);
  }

  21% {
    transform: translateX(-100%);
  }

  96% {
    transform: translateX(-100%);
  }

  100% {
    transform: translateX(0);
  }
}

@keyframes two {
  0% {
    transform: translateX(-100%);
  }

  16% {
    transform: translateX(-100%);
  }

  20% {
    transform: translateX(0);
  }

  36% {
    transform: translateX(0);
  }

  40% {
    transform: translateX(100%);
  }

  41% {
    transform: translateX(-100%);
  }

  100% {
    transform: translateX(-100%);
  }
}

@keyframes three {
  0% {
    transform: translateX(-100%);
  }

  36% {
    transform: translateX(-100%);
  }

  40% {
    transform: translateX(0);
  }

  56% {
    transform: translateX(0);
  }

  60% {
    transform: translateX(100%);
  }

  61% {
    transform: translateX(-100%);
  }

  100% {
    transform: translateX(-100%);
  }
}

@keyframes four {
  0% {
    transform: translateX(-100%);
  }

  56% {
    transform: translateX(-100%);
  }

  60% {
    transform: translateX(0);
  }

  76% {
    transform: translateX(0);
  }

  80% {
    transform: translateX(100%);
  }

  81% {
    transform: translateX(-100%);
  }

  100% {
    transform: translateX(-100%);
  }
}

@keyframes five {
  0% {
    transform: translateX(-100%);
  }

  76% {
    transform: translateX(-100%);
  }

  80% {
    transform: translateX(0);
  }

  96% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(100%);
  }
}

/* /////////////// */
@import url('https://fonts.googleapis.com/css?family=Cardo|Pathway+Gothic+One');
.timeline {
  display: flex;
  margin: 0 auto;
  flex-wrap: wrap;
  flex-direction: column;
  max-width: 700px;
  position: relative;
}
.timeline__content-title {
  font-weight: normal;
  font-size: 66px;
  margin: -10px 0 0 0;
  transition: 0.4s;
  padding: 0 10px;
  box-sizing: border-box;
  /* font-family: 'Pathway Gothic One', sans-serif; */
  color: #fff;
}
.timeline__content-desc {
  margin: 0;
  font-size: 15px;
  box-sizing: border-box;
  color: rgba(255, 255, 255, 0.7);
  /* font-family: Cardo; */
  font-weight: normal;
  line-height: 25px;
}
.timeline:before {
  position: absolute;
  left: 50%;
  width: 2px;
  height: 100%;
  margin-left: -1px;
  content: "";
  background: rgba(255, 255, 255, 0.07);
}
@media only screen and (max-width: 767px) {
  .timeline:before {
    left: 40px;
  }
}
.timeline-item {
  padding: 40px 0;
  opacity: 0.3;
  filter: blur(2px);
  transition: 0.5s;
  box-sizing: border-box;
  width: calc(50% - 40px);
  display: flex;
  position: relative;
  transform: translateY(-80px);
}
.timeline-item:before {
  content: attr(data-text);
  letter-spacing: 3px;
  width: 100%;
  position: absolute;
  color: rgba(255, 255, 255, 0.5);
  font-size: 13px;
  /* font-family: 'Pathway Gothic One', sans-serif; */
  border-left: 2px solid rgba(255, 255, 255, 0.5);
  top: 70%;
  margin-top: -5px;
  padding-left: 15px;
  opacity: 0;
  right: calc(-100% - 56px);
}
.timeline-item:nth-child(even) {
  align-self: flex-end;
}
.timeline-item:nth-child(even):before {
  right: auto;
  text-align: right;
  left: calc(-100% - 56px);
  padding-left: 0;
  border-left: none;
  border-right: 2px solid rgba(255, 255, 255, 0.5);
  padding-right: 15px;
}
.timeline-item--active {
  opacity: 1;
  transform: translateY(0);
  filter: blur(0px);
}
.timeline-item--active:before {
  top: 50%;
  transition: 0.3s all 0.2s;
  opacity: 1;
}
.timeline-item--active .timeline__content-title {
  margin: -50px 0 20px 0;
}
@media only screen and (max-width: 767px) {
  .timeline-item {
    align-self: baseline !important;
    width: 100%;
    padding: 0 30px 150px 80px;
  }
  .timeline-item:before {
    left: 10px !important;
    padding: 0 !important;
    top: 50px;
    text-align: center !important;
    width: 60px;
    border: none !important;
  }
  .timeline-item:last-child {
    padding-bottom: 40px;
  }
}
.timeline__img {
  max-width: 100%;
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.4);
}
.timeline-container {
  width: 100%;
  position: relative;
  padding: 80px 0;
  transition: 0.3s ease 0s;
  background-attachment: fixed;
  background-size: cover;
}
.timeline-container:before {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: #00101aa6;
  content: "";
}
.timeline-header {
  width: 100%;
  text-align: center;
  margin-bottom: 80px;
  position: relative;
}
.timeline-header__title {
  color: #fff;
  font-size: 46px;
  /* font-family: Cardo; */
  font-weight: normal;
  margin: 0;
}
.timeline-header__subtitle {
  color: rgba(255, 255, 255, 0.5);
  /* font-family: 'Pathway Gothic One', sans-serif; */
  font-size: 16px;
  letter-spacing: 5px;
  margin: 10px 0 0 0;
  font-weight: normal;
}
.demo-footer {
  padding: 60px 0;
  text-align: center;
}
.demo-footer a {
  color: #999;
  display: inline-block;
  /* font-family: Cardo; */
}
