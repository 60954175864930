@media (max-width: 480px) {
    .product-banner-adhiba img{
      height: 30vh;
    }
  }

.product-des-section-adhiba {

    .products-main-contents-adhiba {
        padding: 3% 0;
    }

    .products-right-contents-adhiba {
        /* padding: 5% 0; */
        display: flex;
        justify-content: center;
        /* align-items: center; */
        flex-direction: column;
    }

    .products-right-contents-adhiba h3 {
        color: #393185;
        font-weight: bold;
        font-size: 35px;
    }

    .products-right-contents-adhiba .icon-product-adhiba {
        color: #393185;
        font-size: 10px;
    }

    .products-right-contents-adhiba span {
        font-weight: bold;
        color: #000000ad;
        margin-left: 2%;
        font-size: 18px;
    }

    .products-right-contents-adhiba p {
        color: #8c8c8c;
        margin-top: 2%;
        font-size: 14px;
    }
}