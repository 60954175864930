/* HOLISTIC SECURITY SOLUTION */
.holistic-security-section-adhiba {
    background-color: #f5f5f5;
    padding: 3.5% 0;

    .holistic-security-right-contents-adhiba {}

    .holistic-security-right-contents-adhiba h3 {
        font-size: 35px;
        font-weight: 700;
        /* max-width: 60%; */
        text-transform: uppercase;
    }

    .holistic-security-right-contents-adhiba img {
        margin-top: 10px;
    }

    .holistic-security-right-contents-adhiba p {
        color: #8c8c8c;
        max-width: 70%;
        margin-top: 20px;
    }

    .holistic-security-right-contents-adhiba h6 {
        color: #393185;
        font-weight: 700;
        text-transform: underline;
        font-size: 18px;
    }

    /* .holistic-security-right-contents-adhiba h6, img:hover {
        color: #3931857c;
        font-weight: 700;
        cursor: pointer;
    } */

    @media (min-width: 1281px) {
        .holistic-security-right-contents-adhiba h3 {
            max-width: 60%;
        }
    }
}

/* QUICK, SMART AND GREAT SOLUTION  */
.quick-smart-solution-section-adhiba {
    padding: 3.5% 0;

    .quick-smart-solution-heading-adhiba {
        /* text-align: center; */
    }

    .quick-smart-solution-heading-adhiba h3 {
        color: #393185;
        font-size: 25px;
        font-weight: 700;
    }

    .quick-smart-solution-contents-adhiba {
        padding: 3rem 0;
    }

    .quick-smart-solution-left-contents-adhiba {
        /* display: flex;
        justify-content: center; */

    }

    .quick-smart-solution-right-contents-adhiba {}

    .quick-smart-solution-right-contents-adhiba .card {
        outline: none;
        border: none;
    }

    .quick-smart-solution-right-contents-adhiba .card-body {
        background-color: #f5f5f5;
        border-radius: 15px;
        padding: 45px;
        /* margin-top: 30px; */
    }

    .quick-smart-solution-right-contents-adhiba .card-title {
        font-size: 30px;
        font-weight: 700;
    }

    .quick-smart-solution-right-contents-adhiba .card-text {
        color: #8c8c8c;
        width: 85%;
    }

    .quick-smart-solution-right-contents-adhiba .card-link {
        color: #393185;
    }

    .quick-smart-solution-right-contents-adhiba .card-link:hover {
        color: #000000;
        text-decoration: underline;
        font-weight: 700;
    }

    @media (max-width: 480px) {
        .quick-smart-solution-section-adhiba {
            padding: 3.5% 0;
        }
    }

}

/* WE PROVIDE PERFECT SOLUTION */
.We-provide-perfect-solution-section-adhiba {
    padding: 3.5rem 0;
    background-color: #393185;

    .We-provide-perfect-solution-heading-adhiba {
        text-align: center;
    }

    .We-provide-perfect-solution-heading-adhiba h3 {
        color: #fff;
        font-size: 30px;
        text-transform: uppercase;
    }

    .We-provide-perfect-solution-heading-adhiba p {
        color: #b8b8b5;
        font-size: 15px;
    }


    .we-provide-solution-icons-text-adhiba {
        padding: 3rem 0;
        display: flex;
        justify-content: center;
        text-align: center;
    }

    .we-provide-solution-icons-text-adhiba p {
        color: #f5f5f5;
        margin-top: 17px;
    }

 


}

/* OUR SOLUTION */
.Our-solutions-section-adhiba {
    padding: 3.5rem 0;

    .Our-solutions-left-contents-adhiba {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .Our-solutions-left-contents-adhiba {}

    .Our-solutions-left-contents-adhiba h3 {
        font-size: 150px;
        color: #393185;
    }

    .Our-solutions-left-contents-adhiba p {
        color: #8c8c8c;
        width: 85%;
    }


}

/* READY TO GO  */
.ready-to-go-section-adhiba {
    padding: 3.5rem 0;
    background-color: #393185;


    .ready-to-go-heading-adhiba {
        text-align: center;
        /* width: 75%; */
    }

    .ready-to-go-heading-adhiba h3 {
        font-size: 30px;
        color: #f5f5f5;
    }

    .ready-to-go-heading-adhiba p {
        color: #b8b8b5;
        font-size: 15px;
    }


    .ready-to-go-button-adhiba {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .ready-to-go-button-adhiba button {

        background-color: #fff;
        /* Change to any color you like */
        color: #393185;
        /* Text color */
        padding: 15px 35px;
        /* Adjust padding for size */
        border: none;
        /* Removes default border */
        border-radius: 5px;
        /* Rounded corners */
        cursor: pointer;
        /* Changes cursor on hover */
        text-align: center;
        /* Centers text */
        font-size: 14px;
        /* Adjust font size */
        display: inline-block;
        /* Ensures proper alignment */
    }

    .ready-to-go-button-adhiba button:hover {
        background-color: #f5f5f5;
        /* Slightly darker shade on hover */
    }

}