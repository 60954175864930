/* BANNER SECTION */
.banner-section-ourbusiness-adhiba {
    padding: 5% 0;
    background-color: #f5f5f5;

    .banner-left-contents-adhiba {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }

    .banner-left-contents-adhiba h3 {

        font-size: 35px;
        font-weight: 800;
        text-transform: uppercase;
    }

    .banner-left-contents-adhiba p {
        margin-top: 17px;
        font-size: 17px;
        color: #8c8c8c;
        line-height: 130%;
        width: 88%;
        text-align: justify;
    }

    .banner-left-contents-adhiba button {
        margin-top: 17px;
        background-color: #393185;
        /* Change to any color you like */
        color: white;
        /* Text color */
        padding: 15px 35px;
        /* Adjust padding for size */
        border: none;
        /* Removes default border */
        border-radius: 5px;
        /* Rounded corners */
        cursor: pointer;
        /* Changes cursor on hover */
        text-align: center;
        /* Centers text */
        font-size: 14px;
        /* Adjust font size */
        display: inline-block;
        /* Ensures proper alignment */
    }

    .banner-left-contents-adhiba button:hover {
        background-color: #39318585;
        /* Slightly darker shade on hover */
    }

    /* Mobile Devices */
    @media (max-width: 480px) {
        .banner-left-contents-adhiba {
            padding: 3% 3%;
        }

        .banner-left-contents-adhiba h3 {
            font-size: 25px;
        }

        .banner-left-contents-adhiba p {
            margin-top: 14px;
            font-size: 17px;
            width: 96%;
        }

        .banner-left-contents-adhiba button {
            padding: 12px 20px;
            font-size: 14px;
            margin-bottom: 10px;
        }
    }

}

/* OUR MISSION */
.four-cards-section-adhiba {
    padding: 5% 0;

    .four-cards-main-card-div-adhiba {
        margin: 15px 0 0 -50px;
    }

    .four-cards-main-card-div-adhiba .card {
        border: none;
        outline: none;
        background-color: transparent;
        height: 25vh;
    }

    .four-cards-main-card-div-adhiba .card-body {
        background-color: #f5f5f5;
        display: flex;
        justify-content: center;
        flex-direction: column;
        padding: 0 30px;
        /* border-radius: 10px; */
        box-shadow: 0 2px 20px 0 rgba(100, 100, 218, 0.068);
    }

    .four-cards-main-card-div-adhiba .card-title {
        font-size: 25px;
        font-weight: bold;
    }

    .four-cards-main-card-div-adhiba .card-text {
        font-size: 14px;
        color: #3931855e;
    }

    /* Mobile Devices */
    @media (max-width: 600px) {

        .four-cards-main-card-div-adhiba {
            margin: 0;
        }

    }

}

/* WE BELIEVE IN */
.we-believein-section-adhiba {
    padding: 0 0 3.5% 0;

    .we-believein-heading-adhiba {}

    .we-believein-heading-adhiba h3 {
        color: #393185;
        font-weight: 800;
        text-align: center;
        text-transform: uppercase;
    }



    .we-believein-4box-contents-adhiba .card {
        border: none;
        outline: none;
        border-radius: 20px;
        padding: 50px;
        background-color: #f5f5f5;
    }

    .we-believein-4box-contents-adhiba .card-title {
        color: #3931858a;
        font-size: 200px;
        font-weight: 700;
    }

    .we-believein-4box-contents-adhiba .card-subtitle {
        font-size: 32px;
        font-weight: 700;
    }

    .we-believein-4box-contents-adhiba .card-text {
        font-size: 17px;
        color: #8c8c8c;
        margin-top: 17px;
    }

    @media (max-width: 480px) {
        .we-believein-4box-contents-adhiba .card {
            padding: 10px;
        }

        .we-believein-4box-contents-adhiba .card-title {
            font-size: 100px;
        }

        .we-believein-4box-contents-adhiba .card-subtitle {
            font-size: 25px;
            font-weight: 700;
        }

        .we-believein-4box-contents-adhiba {
            margin: 3.5%;
        }
    }

}

/* WE PARTNER */
.We-partner-main-section-adhiba {
    padding: 1% 0 3.5% 0;
    background-color: #f5f5f5;

    .We-partner-left-contents-adhiba {
        z-index: 1;
        margin-top: 25%;
    }

    .We-partner-left-contents-adhiba .card {
        border: none;
        outline: none;
        /* background-color: #f5f5f5; */
        box-shadow: 0px 2px 20px 2px rgba(150, 150, 220, .18);
        width: 40rem;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .We-partner-left-contents-adhiba .card-body {
        padding: 30px;
    }

    .We-partner-left-contents-adhiba .card-title {
        font-size: 23px;
        font-weight: 600;
        color: #393185;
    }

    .We-partner-left-contents-adhiba .card-text {
        /* font-size: 17px; */
        width: 80%;
        margin-top: 10px;
        color: #8c8c8c;
    }

    .We-partner-left-contents-adhiba button {

        background-color: #393185;
        /* Change to any color you like */
        color: white;
        /* Text color */
        padding: 15px 35px;
        /* Adjust padding for size */
        border: none;
        /* Removes default border */
        border-radius: 5px;
        /* Rounded corners */
        cursor: pointer;
        /* Changes cursor on hover */
        text-align: center;
        /* Centers text */
        font-size: 14px;
        /* Adjust font size */
        display: inline-block;
        /* Ensures proper alignment */
    }

    .We-partner-left-contents-adhiba button:hover {
        background-color: #39318585;
        /* Slightly darker shade on hover */
    }

    @media (max-width: 992px) {
        .We-partner-main-section-adhiba {
            padding: 3.5%;
        }

        .We-partner-left-contents-adhiba .card {
            width: fit-content;
        }

        .We-partner-left-contents-adhiba {
            z-index: 1;
            margin-top: 0
        }
    }

}

/* WE OFFER  */
.we-offer-innovative-section-adhiba {
    padding: 3.5% 0 7% 0;

    .we-offer-innovative-heading-adhiba {
        /* position: sticky;
        top: 10px; */
        z-index: 1;
    }

    .we-offer-innovative-heading-adhiba h3 {
        font-size: 32px;
        font-weight: 700;
    }

    .we-offer-innovative-heading-adhiba p {
        font-size: 17px;
        max-width: 80%;
        color: #8c8c8c;
    }

    .we-offer-innovative-right-contents-adhiba {}

    .we-offer-innovative-right-contents-adhiba .card {
        border: none;
        outline: none;
        background-color: #393185;
    }

    .we-offer-innovative-right-contents-adhiba .card-body {
        padding: 40px;
    }

    .we-offer-innovative-right-contents-adhiba .card-title {
        color: white;
        font-size: 40px;
    }

    .we-offer-innovative-right-contents-adhiba .card-text {
        color: rgba(255, 255, 255, 0.555);
        font-size: 17px;
        width: 90%;
        margin-top: 17px;

    }

    .we-offer-innovative-right-contents-adhiba .card {
        overflow: hidden;
        position: relative;
        transition: transform 0.3s ease, box-shadow 0.3s ease;
    }

    .we-offer-innovative-right-contents-adhiba .card:hover {
        transform: translateY(-10px);
        box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
    }

    .we-offer-hover-4cards-contents-adhiba {
        font-size: 17px;
        color: white;
        margin-top: 20%;
        animation: slide-up 0.5s forwards;
        opacity: 0;
    }

    .we-offer-innovative-right-contents-adhiba .card:hover .we-offer-hover-4cards-contents-adhiba {
        animation: slide-up 0.5s forwards;
        opacity: 1;
    }

    @keyframes slide-up {
        from {
            transform: translateY(20px);
            opacity: 0;
        }

        to {
            transform: translateY(0);
            opacity: 1;
        }
    }


}