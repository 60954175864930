 /* LANDING BANNER */
 /* Mobile Devices */
 @media (max-width: 480px) {
   .Landing-banner-aboutus img {
     height: 30vh;
   }
 }

 /* ABOUT US DESIGN LEFT AND RIGHT CARD DESIGN */
 .left-right-card-adhiba {
   padding: 3.5% 0;
   background-color: #f5f5f5;

   .right-contents-adhiba {
     display: flex;
     justify-content: center;
     flex-direction: column;
   }

   .right-contents-adhiba h3 {
     font-weight: bold;
     font-size: 35px;
   }

   .right-contents-adhiba p {
     color: #8c8c8c;
     margin-top: 17px;
   }


   /* Mobile Devices */
   @media (max-width: 480px) {
     padding: 3.5% 0;
   }

   /* Low resolution Tablets and iPads */
   @media (min-width: 481px) and (max-width: 767px) {
     padding: 3.5% 0;
   }

   /* Tablets iPads (Portrait) */
   @media (min-width: 768px) and (max-width: 1024px) {
     padding: 3.5% 0;
   }

   /* Laptops and Desktops */
   @media (min-width: 1025px) and (max-width: 1280px) {
     padding: 3.5% 0;
   }

   /* Big boi Monitors */
   @media (min-width: 1281px) {
     padding: 3.5% 0;
   }

 }

 /* ACCORDIAN AND LEFT CONTENT DESIGN */
 .Accordion-About-Adhiba {
   /* height: 50vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column; */
   padding: 3.5% 0;

   .left-about-contents-adhiba {
     display: flex;
     justify-content: center;
     align-items: center;
   }

   .left-about-contents-adhiba h3 {
     font-weight: bold;
     font-size: 35px;
   }

   .left-about-contents-adhiba p {
     color: #8c8c8c;
     margin-top: 17px;
     width: 85%;
   }

   .left-about-contents-adhiba button {

     background-color: #393185;
     /* Change to any color you like */
     color: white;
     /* Text color */
     padding: 15px 35px;
     /* Adjust padding for size */
     border: none;
     /* Removes default border */
     border-radius: 5px;
     /* Rounded corners */
     cursor: pointer;
     /* Changes cursor on hover */
     text-align: center;
     /* Centers text */
     font-size: 14px;
     /* Adjust font size */
     display: inline-block;
     /* Ensures proper alignment */
   }

   .left-about-contents-adhiba button:hover {
     background-color: #39318585;
     /* Slightly darker shade on hover */
   }


   /*accordion */

   .Accordion-About-Adhiba {
     padding: 7rem 0;
   }

   a {
     text-decoration: none;
   }

   /* Accordion Styles */
   .accordion {
     outline: none;
     border: none;
     box-shadow: 0 2px 25px 0 rgba(110, 130, 208, 0.18) !important;
     background-color: #fff;
   }



   /* Accordion Item */
   .accordion-item {
     border: none;
     margin-bottom: 10px;
     border-radius: 10px;
   }

   /* Accordion Button */
   .accordion-button {
     text-decoration: none;
     background-color: #241d8c !important;
     color: #fff !important;
     position: relative;
     box-shadow: none !important;
     border: none;
     padding: 15px 20px;
     font-size: 16px;
     transition: background-color 0.3s, color 0.3s;
     display: flex;
     align-items: center;
     justify-content: space-between;
   }

   /* First Accordion Specific Style */
   .accordion-button.first-accordion {
     background-color: #241d8c !important;
     color: white !important;
     border: 2px solid #241d8c;
   }

   .accordion-button.first-accordion:hover {
     background-color: #EAE8FA !important;
     color: #241d8c !important;
   }

   /* Opened State for First Accordion */
   .accordion-button.first-accordion:not(.collapsed) {
     background-color: #2f2f2f !important;
     color: #fff !important;
   }

   /* Arrow Styling */
   .accordion-button::before {
     padding: 1rem;
   }

   .accordion-button::after {
     content: "";
     display: inline-block;
     /* width: 20px;
  height: 20px; */
     text-align: center;
     border-radius: 50%;
     /* Arrow color */
     /* background-color: #fff; */
     /* Arrow background */
     transition: transform 0.3s ease, border-color 0.3s ease;
   }

   /* Arrow Color Change When Opened */
   .accordion-button:not(.collapsed)::after {
     /* transform: rotate(-135deg); */
   }

   /* Focus and Active State */
   .accordion-button:focus,
   .accordion-button:active {
     background-color: #241d8c !important;
     outline: none !important;
     box-shadow: none;
     text-decoration: none;
   }

   /* Hover State */
   .accordion-button:hover {
     text-decoration: none;
     background: #EAE8FA !important;
     color: #241d8c !important;
   }

   /* Accordion Collapse */
   .accordion-collapse {
     border-top: 1px solid #e9ecef;
   }

   /* Accordion Body */
   .accordion-body {
     padding: 20px;
     font-size: 14px;
     color: #8c8c8c;
     background-color: #f9f9f9;
   }

   /* Accordion Button Transition */
   .accordion-button,
   .accordion-body {
     transition: all 0.3s ease;
   }

 }

 /*  HOW WE WORK */
 .How-we-work-section-adhiba {
   padding: 3.5% 0 3.5% 0;
   background-color: #f5f5f5;

   .How-we-work-heading-div {
     text-align: center;
   }

   .How-we-work-heading-div h3 {
     color: #393185;
     font-weight: bold;
     font-size: 35px;
   }

   .How-we-work-heading-div p {
     color: #8c8c8c;
     text-align: center;
   }

   .How-we-work-heading-cards {}


   .How-we-work-heading-cards .card {
     position: relative;
     padding: 17px 17px 40px 17px;
     border: none;
     outline: none;
     color: white;
     margin-top: 17px;
   }

   .How-we-work-heading-cards h5 {
     text-align: start;
     font-size: 48px;
     font-weight: 700;
     opacity: .2;
     margin-top: 17px;
   }

   .How-we-work-heading-cards h6 {
     text-align: start;
     font-size: 24px;
     font-weight: 700;
     margin-top: 17px;
   }

   .How-we-work-heading-cards p {
     text-align: start;
     font-size: 15px;
     margin-top: 17px;
     color: rgba(255, 255, 255, 0.658);
     font-weight: lighter;
   }

   .hover-icon-adhiba {
     height: 45px;
     width: 45px;
     display: flex;
     justify-content: center;
     align-items: center;
     background-color: #f5f5f5;
     color: #393185;
     border-radius: 50%;
     visibility: hidden;
     transition: visibility 0.3s, opacity 0.3s;
     opacity: 0;
     position: absolute;
     top: 89%;
     left: 85%;
     transform: translate(-50%, -50%);
     cursor: pointer;
   }


   .hover-icon-adhiba {
     visibility: visible;
     opacity: 1;
   }

 }

 /* 2 IMAGES */
 .mission-vission-section {
   padding: 3.5% 0 3.5% 0;

   .mission-vission-heading-div {
     text-align: center;
   }

   .mission-vission-heading-div h3 {
     color: #393185;
     font-weight: bold;
     font-size: 35px;
   }

   .mission-vission-heading-div p {
     color: #8c8c8c;
   }

   .mission-vission-2card-left-div {
     /* text-align: start; */
   }

   .mission-vission-2card-left-div .card {
     outline: none;
     border: none;
     background-color: #f5f5f5;
   }

   .mission-vission-2card-left-div .card-body {
     border-right: 10px solid #241d8c;
     padding: 2.5rem 1rem;
     border-radius: 10px;
   }

   .mission-vission-2card-left-div .card-title {
     font-size: 32px;
     color: #241d8c;
     font-weight: 500;
     margin-top: 10px;
   }

   .mission-vission-2card-left-div .card-text {
     color: #8c8c8c;
     margin-top: 15px;
     width: 80%;
   }

   .mission-vission-2card-right-div {
     /* text-align: start; */
     margin-top: 10%;
   }

   .mission-vission-2card-right-div .card {
     outline: none;
     border: none;
     background-color: #f5f5f5;
     margin: 2.5% 0;
   }

   .mission-vission-2card-right-div .card-body {
     border-left: 10px solid #241d8c7e;
     padding: 2.5rem 1rem;
     border-radius: 10px;
   }

   .mission-vission-2card-right-div .card-title {
     font-size: 32px;
     color: #241d8c;
     font-weight: 500;
     margin-top: 10px;
   }

   .mission-vission-2card-right-div .card-text {
     color: #8c8c8c;
     margin-top: 15px;
     width: 80%;
   }

 }

 /* TESTIMONIALS */
 :root {
   --theme-color: #f5f5f5;
   --bg-color: rgba(79, 48, 183, 0.06);
   --comp-color: #ff6584;
   --gradient: #f5f5f5;
 }

 .Testimonial-about-adhiba {

   .Testimonial-heading-div {
     text-align: center;
   }

   .Testimonial-heading-div h3 {
     color: #393185;
     font-weight: bold;
   }

   .Testimonial-heading-div p {
     color: #8c8c8c;
     text-align: center;
   }

   /* .bg-color {
  background: var(--bg-color);
} */

   .half-color-box {
     background: var(--gradient);
     height: 300px;
     /* margin-bottom: 100px; */
   }

   .por {
     position: relative;
   }

   /* .review-box .quote {
  position: absolute;
  width: 40px;
  right: 10px;
  bottom: 10px;
} */

   .test-arrowbox {
     position: absolute;
     right: 20px;
     display: flex;
     top: 70px;
   }

   .review-box {
     border-radius: 10px;
     background: #fff;
     box-shadow: 0px 3px 20px rgba(0, 0, 0, 0.1);
     padding: 50px 30px 50px 30px;
   }

   .review-box h5 {
     margin: 0;
   }

   .review-box p {
     margin-bottom: 0;
     margin-top: 10px;
     color: #7d8597;
   }

   .media-body {
     margin-left: 20px;
   }

   .user-post {
     font-size: 14px;
     margin: 0 !important;

   }

   .swiper-testimonial {
     padding: 100px 20px;
     padding-top: 30px;
   }

   /* .half-color-box {
  background: var(--gradient);
  height: 300px;
  margin-bottom: 140px;
} */

   .test-arrowbox .swiper-button-next-test,
   .test-arrowbox .swiper-button-prev-test {
     margin-right: 20px;
     /* background: var(--comp-color);2 */
     border-radius: 50%;
     --size: 55px;
     width: var(--size);
     height: var(--size);
     display: flex;
     align-items: center;
     justify-content: center;
   }

   .arrow-right {
     transform: rotate(180deg);
     margin-top: -8px;
   }

   .arrow {
     cursor: pointer;
     transition: all 0.2s ease-in;
   }

   .arrow:hover {
     transform: translateX(-15px);
   }

   .testmonial-icon-adhiba {
     font-size: 45px;
     color: #8c8c8c5d;
   }

 }