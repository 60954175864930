
 *{
  font-family: "kabob";
 }
   

  
    @media only screen and (min-width: 800px) {
  
  
      .mobile-form{
       display: none;
      }
        
      
      .desktop-form{
    
.formheading {
  font-size: 3rem;
  word-spacing: 10px;
  font-weight: 100;
  line-height: 1.5;
  text-transform: uppercase;
  white-space: nowrap;
  overflow: hidden;
  position: relative;
 
}

.formheading .form2heading {
  font-size: 40px;
}

.message {

  background-color: #ffffff;
  color: #3b2a98;
  display: block;
  font-weight: 900;
  overflow: hidden;
  position: absolute;
 

  animation: openclose 3s ease-in-out infinite;
}


.Formbodymain{
  background-color: #151515;
padding-top: 30px;
  height: fit-content;
  /* width: fit-content; */
  padding: 50px;
}




 .controls{
     text-align: left;
     position: relative;
     input[type="text"],
     input[type="email"],
    input[type="number"],
   input[type="date"],
     input[type="tel"],
     textarea,
  
     select{
         padding: 12px;
         font-size: 14px;
         
      
         /* margin-bottom: 18px; */
         border: none;
         outline: none;
         background: transparent;
         color: #FFF;
         border-bottom:  1px  solid rgba(255, 255, 255, 0.7);
         margin-bottom: 70px;
     }
 
     .fa-sort{
         position:absolute;
         right: 10px;
         top: 17px;
         color: #ffffff;
     }
     select{
         @include appearance(none);
         cursor: pointer;
     }

 
    
     }
     textarea{
         resize: none;
         height: 230px;
         
     }
#email {
  width: 100%;
}
#text {
  width: 100%;
}
#country {
  width: 100%;
}
#comments{
  width: 100%;
  border:  1px  solid rgba(255, 255, 255, 0.7);
  
}
#street-number{
  width: 100%;
}
#name{
  width: 100%;
}
.inright{
  padding-left: 20px;
}
.formbut{
  display: flex;
  justify-content: end;
}
 
.sumform{
   cursor: pointer;
   background-color: #000000;
   border: none;
   color: #807979;
   padding: 15px 35px ;
  border-radius: 15px;
  }
 

  
        }
        



    @keyframes openclose {
      0% {
        top: 0.2rem;
        width: 0;
      }
      5% {
        width: 0;
      }
      15% {
        width: 400px;
      }
      30% {
        top: 0.2rem;
        width: 400px;
      }
      33% {
        top: 0.2rem;
        width: 0;
      }
      35% {
        top: 0.2rem;
        width: 0;
      }
      38% {
        top: -4.5rem;
        
      }
      48% {
        top: -4.5rem;
        width: 400px;
      }
      62% {
        top: -4.5rem;
        width: 400px;
      }
      66% {
        top: -4.5rem;
        width: 0;
        text-indent: 0;
      }
      71% {
        top: -4.5rem;
        width: 0;
        text-indent: 5px;
      }
      86% {
        top: -4rem;
        width: 0px;
      }
      95% {
        top: -4rem;
        width: 0px;
      }
      98% {
        top: 0;
        width: 0;
        text-indent: 5px;
      }
      100% {
        top: 0;
        width: 0;
        text-indent: 0;
      }
    }
    
    
















      }
  
  
  
  
  
    @media only screen and (max-width: 800px) {
  .desktop-form{
    display: none;
  }
  
  
  .mobile-form{
    padding: 50px;
    background-color: #151515;
        
.formheading {
  font-weight: 500px;
 
  
  line-height: 1.5;
  text-transform: uppercase;
  white-space: nowrap;
  overflow: hidden;
  position: relative;
  
}

.formheading .form2heading {
  
}

.message {
text-wrap: nowrap;
  background-color: #ffffff;
  color: #3b2a98;
  display: block;
  font-weight: 500px;
  overflow: hidden;
  position: absolute;
 

  animation: openclose 3s ease-in-out infinite;
}

  

 .controls{
  text-align: left;
  position: relative;
  input[type="text"],
  input[type="email"],
 input[type="number"],
input[type="date"],
  input[type="tel"],
  textarea,

  select{
      padding: 12px;
      font-size: 14px;
      
   
      /* margin-bottom: 18px; */
      border: none;
      outline: none;
      background: transparent;
      color: #FFF;
      border-bottom:  1px  solid rgba(255, 255, 255, 0.7);
      margin-bottom: 70px;
  }

  .fa-sort{
      position:absolute;
      right: 10px;
      top: 17px;
      color: #ffffff;
  }
  select{
      @include appearance(none);
      cursor: pointer;
  }


 
  }
  textarea{
    background-color: #151515;
      resize: none;
      height: 230px;
      
  }
#email {
width: 100%;
}
#text {
width: 100%;
}
#country {
width: 100%;
}
#comments{
width: 100%;
border:  1px  solid rgba(255, 255, 255, 0.7);

}
#street-number{
width: 100%;
}
#name{
width: 100%;
}
.inright{
padding-left: 20px;
}
.formbut{
display: flex;
justify-content: end;
}

.sumform{
cursor: pointer;
background-color: #000000;
border: none;
color: #807979;
padding: 15px 35px  ;
border-radius: 15px;
}

  }
  



  @keyframes openclose {
    0% {
      top: 0.2rem;
      width: 0;
    }
    5% {
      width: 0;
    }
    15% {
      width: 200px;
    }
    30% {
      top: 0.2rem;
      width: 200px;
    }
    33% {
      top: 0.2rem;
      width: 0;
    }
    35% {
      top: 0.2rem;
      width: 0;
    }
    38% {
      top: -4.5rem;
      
    }
    48% {
      top: -4.5rem;
      width: 200px;
    }
    62% {
      top: -4.5rem;
      width: 200px;
    }
    66% {
      top: -4.5rem;
      width: 0;
      text-indent: 0;
    }
    71% {
      top: -4.5rem;
      width: 0;
      text-indent: 5px;
    }
    86% {
      top: -4rem;
      width: 0px;
    }
    95% {
      top: -4rem;
      width: 0px;
    }
    98% {
      top: 0;
      width: 0;
      text-indent: 5px;
    }
    100% {
      top: 0;
      width: 0;
      text-indent: 0;
    }
  }
  
  
















    }
  
 


















 
